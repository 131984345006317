import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import ProductService from 'services/ProductService';

export const initialState = {
	loading: false,
	dataLoading: false,
	message: '',
	showMessage: false,
	product: null,
	list: null,
	schema: null,
	uiSchema: null,
	data: null,
	error: false
}

export const updateSchema = createAsyncThunk('product/updateSchema', async (data, { rejectWithValue }) => {
	try {
		const response = await ProductService.updateSchema(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getPageSchema = createAsyncThunk('product/schema', async (data, { rejectWithValue }) => {
	try {
		const response = await ProductService.pageSchemas(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const showProduct = createAsyncThunk('product/show', async (data, { rejectWithValue }) => {
	try {

		const response = await ProductService.showProduct(data)
		return response.product;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const createProduct = createAsyncThunk('product/create', async (data, { rejectWithValue }) => {
	try {

		const response = await ProductService.createProduct(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getProductList = createAsyncThunk('product/list', async (data, { rejectWithValue }) => {
	try {

		const response = await ProductService.listProducts(data)
		return response.products;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

const reducerName = 'product';
export const productSlice = createSlice({
	name: reducerName,
	initialState,
	reducers: {
		resetProduct: (state, action) => {
			state = initialState;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(showProduct.pending, (state) => {
				state.dataLoading = true

			})
			.addCase(showProduct.fulfilled, (state, action) => {
				state.dataLoading = false
				state.product = action.payload
			})
			.addCase(showProduct.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.dataLoading = false
			})
			.addCase(createProduct.pending, (state) => {
				state.loading = true
				message.loading({ content: 'Updating...', key: reducerName, duration: 1000 });

			})
			.addCase(createProduct.fulfilled, (state, action) => {
				state.loading = false
				state.product = action.payload
				message.success({ content: 'Done!', key: reducerName });
			})
			.addCase(createProduct.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
				message.error({ content: 'Error!', key: reducerName });
			})
			.addCase(getProductList.pending, (state) => {
				state.dataLoading = true

			})
			.addCase(getProductList.fulfilled, (state, action) => {
				state.dataLoading = false
				state.products = action.payload
			})
			.addCase(getProductList.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.dataLoading = false
			})
			.addCase(updateSchema.pending, (state) => {
				state.loading = true
				message.loading({ content: 'Updating...', key: reducerName, duration: 1000 });
				state.error = false
			})
			.addCase(updateSchema.fulfilled, (state, action) => {
				state.loading = false
				state.error = false
				state.product = action.payload
				message.success({ content: 'Done!', key: reducerName });
			})
			.addCase(updateSchema.rejected, (state, action) => {
				state.message = action.payload
				state.error = true
				state.showMessage = true
				state.loading = false
				message.error({ content: 'Error!', key: reducerName });
				throw new Error("Unable To Update");
			})
			.addCase(getPageSchema.pending, (state) => {
				state.dataLoading = true
				state.data = null

			})
			.addCase(getPageSchema.fulfilled, (state, action) => {
				state.dataLoading = false
				state.loading = false
				state.schema = action.payload.schema
				state.uiSchema = action.payload.uiSchema
				if (action.payload.data && action.payload.data.length)
					state.list = action.payload.data
				else
					state.data = action.payload.data
			})
			.addCase(getPageSchema.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.dataLoading = false
				state.loading = false
			})

	},
})

export const {
	resetProduct
} = productSlice.actions

export default productSlice.reducer