import fetch from 'auth/FetchInterceptor'

const CountryService = {}

CountryService.loadCountries = function (data) {
	return fetch({
		url: '/country/list',
		method: 'get'
	})
}


export default CountryService;