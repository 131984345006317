import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import BusinessService from 'services/BusinessService';

export const initialState = {
	loading: false,
	dataLoading: false,
	message: '',
	showMessage: false,
	business:null
}

export const loadBusiness = createAsyncThunk('business/details',async (data, { rejectWithValue }) => {
	try {
		
		const response = await BusinessService.loadBusiness()
		return response.business;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateBusiness = createAsyncThunk('business/update',async (data, { rejectWithValue }) => {
	try {
		
		const response = await BusinessService.updateBusiness(data)
		return response.business;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateBusinessLogo = createAsyncThunk('business/uploadLogo',async (data, { rejectWithValue }) => {
	try {
		
		const response = await BusinessService.updateBusinessLogo(data)
		return response.business;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

const reducerName = 'business';
export const businessSlice = createSlice({
	name: reducerName,
	initialState,
	reducers: {

	},
	extraReducers: (builder) => {
		builder
			.addCase(loadBusiness.pending, (state) => {
				state.dataLoading = true

			})
			.addCase(loadBusiness.fulfilled, (state, action) => {
				state.dataLoading = false
				state.business = action.payload
			})
			.addCase(loadBusiness.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.dataLoading = false
			})
			.addCase(updateBusiness.pending, (state) => {
				state.loading = true
				message.loading({ content: 'Updating...' , key:reducerName ,duration:1000});
			})
			.addCase(updateBusiness.fulfilled, (state, action) => {
				state.loading = false
				state.business = action.payload
				message.success({ content: 'Done!', key:reducerName });
			})
			.addCase(updateBusiness.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
				message.error({ content: 'Error!', key:reducerName });
			})
			.addCase(updateBusinessLogo.pending, (state) => {
				state.loading = true
				message.loading({ content: 'Updating...' , key:reducerName ,duration:1000});
			})
			.addCase(updateBusinessLogo.fulfilled, (state, action) => {
				state.loading = false
				message.success({ content: 'Done!', key:reducerName });
			})
			.addCase(updateBusinessLogo.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
				message.error({ content: 'Error!', key:reducerName });
			})
	},
})

export const { 

} = businessSlice.actions

export default businessSlice.reducer