import fetch from 'auth/FetchInterceptor'

const ProductService = {}

ProductService.showProduct = function (data) {
	return fetch({
		url: '/product/show',
		method: 'get',
		params: data
	})
}

ProductService.listProducts = function (data) {
	return fetch({
		url: '/product/list',
		method: 'get'
	})
}

ProductService.updateProduct = function (data) {
	return fetch({
		url: '/product/update',
		method: 'put',
		data: data
	})
}

ProductService.createProduct = function (data) {
	return fetch({
		url: '/product/create',
		method: 'post',
		data: data
	})
}

ProductService.deleteProduct = function (data) {
	return fetch({
		url: '/product/delete',
		method: 'delete',
		data: data
	})
}

ProductService.updateUserProfileImage = function (data) {
	return fetch({
		url: '/product/uploadProfileImage',
		method: 'post',
		data: data
	})
}

ProductService.pageSchemas = function (data) {
	return fetch({
		url: '/product/schema',
		method: 'get',
		params: data
	})
}

ProductService.updateSchema = function (data) {
	return fetch({
		url: '/product/updateSchema',
		method: 'put',
		data: data
	})
}


export default ProductService;