import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import ProductCategoryService from 'services/ProductCategoryService';

export const initialState = {
	loading: false,
	dataLoading: false,
	message: '',
	showMessage: false,
	productCategory:null,
	productCategories:[]
}

export const showProductCategory = createAsyncThunk('productCategory/show',async (data, { rejectWithValue }) => {
	try {
		
		const response = await ProductCategoryService.showProductCategory()
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const createProductCategory = createAsyncThunk('productCategory/create',async (data, { rejectWithValue }) => {
	try {
		
		const response = await ProductCategoryService.createProductCategory(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateProductCategory = createAsyncThunk('productCategory/update',async (data, { rejectWithValue }) => {
	try {
		
		const response = await ProductCategoryService.updateProductCategory(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const listProductCategory = createAsyncThunk('productCategory/list',async (data, { rejectWithValue }) => {
	try {
		
		const response = await ProductCategoryService.listProductCategory(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})



const reducerName = 'productCategory';
export const productCategorySlice = createSlice({
	name: reducerName,
	initialState,
	reducers: {

	},
	extraReducers: (builder) => {
		builder
			.addCase(showProductCategory.pending, (state) => {
				state.dataLoading = true

			})
			.addCase(showProductCategory.fulfilled, (state, action) => {
				state.dataLoading = false
				state.productCategory = action.payload
			})
			.addCase(showProductCategory.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.dataLoading = false
			})
			.addCase(createProductCategory.pending, (state) => {
				state.dataLoading = true

			})
			.addCase(createProductCategory.fulfilled, (state, action) => {
				state.dataLoading = false
				state.productCategory = action.payload
			})
			.addCase(createProductCategory.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.dataLoading = false

			})
			.addCase(updateProductCategory.pending, (state) => {
				state.loading = true
				message.loading({ content: 'Updating...' , key:reducerName ,duration:1000});
			})
			.addCase(updateProductCategory.fulfilled, (state, action) => {
				state.loading = false
				state.productCategory = action.payload
				message.success({ content: 'Done!', key:reducerName });
			})
			.addCase(updateProductCategory.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
				message.error({ content: 'Error!', key:reducerName });
			})
			.addCase(listProductCategory.pending, (state) => {
				state.dataLoading = true

			})
			.addCase(listProductCategory.fulfilled, (state, action) => {
				state.dataLoading = false
				state.productCategories = action.payload.categories
			})
			.addCase(listProductCategory.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.dataLoading = false

			})
	},
})

export const { 

} = productCategorySlice.actions

export default productCategorySlice.reducer