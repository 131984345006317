import fetch from 'auth/FetchInterceptor'

const UserService = {}

UserService.loadUser = function (data) {
	return fetch({
		url: '/auth/userDetails',
		method: 'get'
	})
}

UserService.updateUser = function (data) {
	return fetch({
		url: '/auth/update',
		method: 'put',
		data: data
	})
}

UserService.createUser = function (data) {
	return fetch({
		url: '/auth/create',
		method: 'post',
		data: data
	})
}

UserService.updateUserProfileImage = function (data) {
	return fetch({
		url: '/auth/uploadProfileImage',
		method: 'post',
		data: data
	})
}


export default UserService;