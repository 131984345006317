import React, { useEffect } from 'react';
import { onBlankLayout } from 'store/slices/themeSlice';
import { useDispatch, useSelector } from 'react-redux';

const AppRoute = ({ component: Component, routeKey, blankLayout, ...props }) => {
	const currentBlankLayout = useSelector(state => state.theme.blankLayout);
	const dispatch = useDispatch()

	useEffect(() => {
		const isBlank = blankLayout ? true : false
		
		if (currentBlankLayout !== isBlank) {
			dispatch(onBlankLayout(isBlank))
		}

	}, [])

	return (
		<Component {...props} />
	)
}

export default AppRoute