import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import UserService from 'services/UserService';

export const initialState = {
	loading: false,
	dataLoading: false,
	message: '',
	showMessage: false,
	user:null
}

export const loadUser = createAsyncThunk('user/details',async (data, { rejectWithValue }) => {
	try {
		
		const response = await UserService.loadUser()
		return response.user;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateUser = createAsyncThunk('user/update',async (data, { rejectWithValue }) => {
	try {
		
		const response = await UserService.updateUser(data)
		return response.user;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateUserProfileImage = createAsyncThunk('user/uploadLogo',async (data, { rejectWithValue }) => {
	try {
		
		const response = await UserService.updateUserProfileImage(data)
		return response.user;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

const reducerName = 'user';
export const userSlice = createSlice({
	name: reducerName,
	initialState,
	reducers: {

	},
	extraReducers: (builder) => {
		builder
			.addCase(loadUser.pending, (state) => {
				state.dataLoading = true

			})
			.addCase(loadUser.fulfilled, (state, action) => {
				state.dataLoading = false
				state.user = action.payload
			})
			.addCase(loadUser.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.dataLoading = false
			})
			.addCase(updateUser.pending, (state) => {
				state.loading = true
				message.loading({ content: 'Updating...' , key:reducerName ,duration:1000});
			})
			.addCase(updateUser.fulfilled, (state, action) => {
				state.loading = false
				state.user = action.payload
				message.success({ content: 'Done!', key:reducerName });
			})
			.addCase(updateUser.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
				message.error({ content: 'Error!', key:reducerName });
			})
			.addCase(updateUserProfileImage.pending, (state) => {
				state.loading = true
				message.loading({ content: 'Updating...' , key:reducerName ,duration:1000});
			})
			.addCase(updateUserProfileImage.fulfilled, (state, action) => {
				state.loading = false
				message.success({ content: 'Done!', key:reducerName });
			})
			.addCase(updateUserProfileImage.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
				message.error({ content: 'Error!', key:reducerName });
			})
	},
})

export const { 

} = userSlice.actions

export default userSlice.reducer