import fetch from 'auth/FetchInterceptor'

const PageEditorService = {}

PageEditorService.getPageList = function () {
	return fetch({
		url: '/page/list',
		method: 'get'
	})
}

PageEditorService.pageSchemas = function (data) {
	return fetch({
		url: '/page/schema',
		method: 'get',
		params: data
	})
}

PageEditorService.updateSchema = function (data) {
	return fetch({
		url: '/page/updateSchema',
		method: 'put',
		data: data
	})
}


export default PageEditorService;