import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CountryService from 'services/CountryService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	countryList:[]
}

export const loadCountries = createAsyncThunk('country/list',async (data, { rejectWithValue }) => {
	try {
		
		const response = await CountryService.loadCountries()
		return response.countries;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const countrySlice = createSlice({
	name: 'country',
	initialState,
	reducers: {

	},
	extraReducers: (builder) => {
		builder
			.addCase(loadCountries.pending, (state) => {
				state.loading = true
			})
			.addCase(loadCountries.fulfilled, (state, action) => {
				state.loading = false
				state.countryList = action.payload
			})
			.addCase(loadCountries.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const { 

} = countrySlice.actions

export default countrySlice.reducer