import fetch from 'auth/FetchInterceptor'

const ProductCategoryService = {}

ProductCategoryService.showProductCategory = function (data) {
	return fetch({
		url: '/productCategory/show',
		method: 'get',
		data: data
	})
}
ProductCategoryService.listProductCategory = function (data) {
	return fetch({
		url: '/productCategory/list',
		method: 'get',
		data: data
	})
}

ProductCategoryService.updateProductCategory = function (data) {
	return fetch({
		url: '/productCategory/update',
		method: 'put',
		data: data
	})
}

ProductCategoryService.createProductCategory = function (data) {
	return fetch({
		url: '/productCategory/create',
		method: 'post',
		data: data
	})
}




export default ProductCategoryService;