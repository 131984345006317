import fetch from 'auth/FetchInterceptor'

const BusinessService = {}

BusinessService.loadBusiness = function (data) {
	return fetch({
		url: '/business/details',
		method: 'get'
	})
}

BusinessService.updateBusiness = function (data) {
	return fetch({
		url: '/business/update',
		method: 'put',
		data: data
	})
}

BusinessService.updateBusinessLogo = function (data) {
	return fetch({
		url: '/business/uploadLogo',
		method: 'post',
		data: data
	})
}


export default BusinessService;