import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import country from './slices/countySlice'
import business from './slices/businessSlice'
import user from './slices/userSlice'
import product from './slices/productSlice'
import productCategory from './slices/productCategorySlice'
import pageEditor from './slices/pageEditorSlice'


const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        country,
        business,
        user,
        product,
        productCategory,
        pageEditor,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
