import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'manage-pages.general',
        path: `${APP_PREFIX_PATH}/manage-pages/general`,
        component: React.lazy(() => import('views/app-views/general/page-editor')),
    },
    {
        key: 'manage-pages.productList',
        path: `${APP_PREFIX_PATH}/manage-pages/product-list`,
        component: React.lazy(() => import('views/app-views/products/product-list')),
    },
    {
        key: 'manage-pages.addProduct',
        path: `${APP_PREFIX_PATH}/manage-pages/add-product`,
        component: React.lazy(() => import('views/app-views/products/add-product')),
    },
    {
        key: 'manage-pages.editProduct',
        path: `${APP_PREFIX_PATH}/manage-pages/edit-product/:id`,
        component: React.lazy(() => import('views/app-views/products/edit-product')),
    }
]