import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import PageEditorService from 'services/PageEditorService';

export const initialState = {
	loading: false,
	dataLoading: false,
	message: '',
	showMessage: false,
	pages:[],
	schema:null,
	uiSchema:null,
	data:null,
}

export const getPageList = createAsyncThunk('page/list',async (data, { rejectWithValue }) => {
	try {
		const response = await PageEditorService.getPageList(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateSchema = createAsyncThunk('page/updateSchema',async (data, { rejectWithValue }) => {
	try {
		
		const response = await PageEditorService.updateSchema(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getPageSchema = createAsyncThunk('page/schema',async (data, { rejectWithValue }) => {
	try {
		
		const response = await PageEditorService.pageSchemas(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

const reducerName = 'pageEditor';
export const pageEditorSlice = createSlice({
	name: reducerName,
	initialState,
	reducers: {
		resetPage: (state, action) => {
			state = initialState;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getPageList.pending, (state) => {
				state.dataLoading = true
                state.schema = null
	            state.uiSchema = null
	            state.data = null

			})
			.addCase(getPageList.fulfilled, (state, action) => {
				state.dataLoading = false
				state.pages = action.payload.pagelist
			})
			.addCase(getPageList.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.dataLoading = false
			})
			.addCase(updateSchema.pending, (state) => {
				state.loading = true
				message.loading({ content: 'Updating...' , key:reducerName ,duration:1000});

			})
			.addCase(updateSchema.fulfilled, (state, action) => {
				state.loading = false
				state.product = action.payload
				message.success({ content: 'Done!', key:reducerName });
			})
			.addCase(updateSchema.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
				message.error({ content: 'Error!', key:reducerName });
			})
			.addCase(getPageSchema.pending, (state) => {
				state.dataLoading = true

			})
			.addCase(getPageSchema.fulfilled, (state, action) => {
				state.dataLoading = false
				state.schema = action.payload.schema
	            state.uiSchema = action.payload.uiSchema
	            state.data = action.payload.data
			})
			.addCase(getPageSchema.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.dataLoading = false
			})

	},
})

export const { 
	resetPage
} = pageEditorSlice.actions

export default pageEditorSlice.reducer